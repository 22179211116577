var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "landing-page-view" },
    [
      _vm.showLoad
        ? _c("div", { staticClass: "loading", attrs: { id: "loading" } }, [
            _vm._m(0)
          ])
        : _vm._e(),
      _vm.showHeader()
        ? _c("div", { staticClass: "header-wrapper" }, [
            _c("div", { staticClass: "header" }, [
              _vm._m(1),
              _c("div", { staticClass: "vendor" }, [
                _c(
                  "h2",
                  {
                    staticClass: "highlight",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.appConfig.snippetConfiguration.landingPage
                          .teaserHeadline
                      )
                    }
                  },
                  [_vm._v("Vielen\n                    Dank!")]
                ),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.appConfig.snippetConfiguration.landingPage.teaserText
                    )
                  }
                })
              ])
            ])
          ])
        : _vm._e(),
      _vm._m(2),
      _vm._m(3),
      _c("app-footer", {
        staticClass: "landing-footer",
        attrs: {
          "wrapper-class": "inner-wrapper",
          pages: {
            privacyPolicyId: "5ac355a1e4588f00014b0003",
            imprintId: "5ac34caae4588f00014b0002"
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading-wrapper" }, [
      _c("div", { staticClass: "loading-header" }, [
        _c("img", { attrs: { src: "/gift-header.svg" } })
      ]),
      _c("div", { staticClass: "loading-title" }, [
        _c("h1", { attrs: { id: "loading-title" } }, [
          _vm._v("Ihr Geschenk wartet schon...")
        ]),
        _c("h1", { attrs: { id: "loading-subtitle" } }, [
          _vm._v("Sie werden jetzt zu unserem Partner weitergeleitet")
        ])
      ]),
      _c("img", { staticClass: "loading-image", attrs: { src: "/gift.jpg" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image" }, [
      _c("img", { attrs: { src: "gift-heart.png" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headline row flex-column" }, [
      _c("span", { staticClass: "first" }, [
        _vm._v("\n            Wählen Sie hier Ihr Dankeschön-Angebot\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "offer-wrapper" }, [
      _c("div", { attrs: { id: "embed" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }