














































import {Component} from '@/decorators';
import AppFooter from '@/components/app-footer.vue';
import Vue from 'vue';
import {Getter} from 'vuex-class';
import {ApplicationConfiguration} from '@/model/backend/campaign-api';

@Component({
    components: {AppFooter}
})
export default class LandingPageView extends Vue {
    private pageContent: string = '';
    private showLoad: boolean = false;

    @Getter
    private appConfig: ApplicationConfiguration;

    public mounted(): void {
        this.showLoad = this.$route.params.load && this.$route.params.load === '0' ? false : true;
        if (window.vgEmbed) {
            this.loadOffers();
        } else {
            let scriptTag = window.document.createElement('script');
            scriptTag.src = process.env.VUE_APP_VG_EMBED_SCRIPT;
            scriptTag.onload = this.loadOffers;
            window.document.head.appendChild(scriptTag);
        }
        setTimeout(() => {
            this.showLoad = false;
        }, 2500);

        if (this.appConfig.portalConfiguration.theme === 'breuninger') {
            if (document.querySelector('#breuninger_tracking_script') === null) {
                let tracking_script = document.createElement('script');
                tracking_script.id = 'breuninger_tracking_script';
                tracking_script.type = 'text/javascript';
                tracking_script.src = '//cms.brnstc.de/medias/tracking/emos2_20190307.js';
                tracking_script.addEventListener('load', () => {
                    window.emos3.defaults.siteid = 'Live2';
                    window.emos3.send({
                        content: 'external/dds/landing/external',
                        countryid: 'DE',
                        langid: 'de'
                    });
                });
                document.head.appendChild(tracking_script);
            }
        }
    }

    private showHeader(): boolean {
        try {
            if (!this.appConfig) {
                return false;
            }
            const landingPage = this.appConfig.snippetConfiguration.landingPage;
            return landingPage.teaserText.length > 1 && landingPage.teaserHeadline.length > 1;
        } catch (e) {
            return false;
        }
    }

    private loadOffers(): void {
        window.vgEmbed.no_modal = true;
        window.vgEmbed.load({
            element: 'embed',
            site: process.env.VUE_APP_VG_EMBED_API_URL,
            visitId: this.$route.params.visitId,
            snippetId: this.$route.params.snippetId || process.env.VUE_APP_LANDING_PAGE_SNIPPET_ID,
        });

        this.$nextTick(() => {
            this.triggerResize();
        });
        // dirty but works :)
        setTimeout(() => this.triggerResize(), 200);
        setTimeout(() => this.triggerResize(), 500);
        setTimeout(() => this.triggerResize(), 1000);
    }

    private triggerResize(): void {
        if (typeof (Event) === 'function') {
            window.dispatchEvent(new Event('resize'));
        } else {
            let resizeEvent = window.document.createEvent('UIEvents');
            resizeEvent.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(resizeEvent);
        }
    }
}
